<template>
  <EntityList
    ref="list"
    :config="config"
    :columns="columns"
    :filter-vals="filterVals"
    :filters="filters"
    :formatted-columns="formattedColumns"
    :results-per-page="25"
    :has-insert-btn="false"
    hide-edit
    authority="Measurements_Docking"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './storeModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      dockingOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'notifications',
        endpoint: 'notifications',
        route: 'notifications',
        filtersOpen: true,
        title: {
          single: this.$i18n.t('Notification'),
          plural: this.$i18n.t('Notifications'),
        },
      }
    },
    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'creationDate', label: this.$t('time'), sortable: true },
        {
          key: 'kind',
          label: this.$i18n.t('kind'),
        },
        {
          key: 'title',
          label: this.$i18n.t('title'),
        },
        {
          key: 'message',
          label: this.$i18n.t('description'),
        },
      ]
    },
    formattedColumns() {
      return [
        { key: 'kind', type: 'status' },
        { key: 'creationDate', type: 'datetime' },
      ]
    },
    filters() {
      return [
        {
          name: 'docking',
          label: this.$t('Docking'),
          options: this.dockingOptions,
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$t('Start Date Edit'),
          maxDateFieldIdx: '2',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$t('End Date Edit'),
          minDateFieldIdx: '1',
          maxDate: new Date(),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchDockings').then((response) => {
      this.dockingOptions = response.data
    })
  },
}
</script>
